import styled from 'styled-components';

import backgroundRight from '@src/images/listadeespera/background-right.png';
import background from '@src/images/listadeespera/background.png';
import btnBGImage from '@src/images/background-button.png';

export const PageContent = styled.div`
    ${tw`w-full mt-12 rounded shadow-xl flex`};
    background-color: #1C1A21;
    height: 735px;
    /* background: url(${background}) top no-repeat; */
    /* border: 1px solid #1C1A21; */
`;

export const Left = styled.div`
    ${tw`w-1/2 flex pt-12 pl-12 flex-col`};
`;

export const Title = styled.p`
  ${tw`text-4xl font-bold text-white`};
`;

export const Separator = styled.hr`
    ${tw`mt-6 mb-6 1/12`};
    width: 60px;
    color: #8249E2;
    height: 1px;
    border: none;
    background: #8249E2;
    opacity: 0.6;
`;

export const Text = styled.p`
  ${tw`text-theme-text leading-normal text-base mt-1 mb-4`};
  margin-right: 100px;
`;

export const TextPurple = styled.span`
    color: #8249E2;
`;
export const TextGreen = styled.span`
    color: #41D999;
`;


export const Right = styled.div`
    ${tw`w-1/2 rounded shadow-xl p-6`};
    background: url(${backgroundRight}) right top no-repeat;
`;

export const RightDetailImage = styled.img`
    position:absolute;
    bottom: 80px;
    right: 120px;
`;

export const FormLead = styled.form`
    ${tw`flex-row mt-2`};
`;

export const FormInput = styled.input`
    ${tw` leading-normal rounded-full text-base p-4 w-3/4 mb-6 pl-10`};
    background-color: #211F27;
    border: 0px;
    border: 1px solid #211F27;
    color: white;

    transition: border 0.2s;

    &:hover {
        border: 1px solid #41D999;
    }
    &:focus {
        border: 1px solid #41D999;
    }
`;

export const FormLabel = styled.p`
  ${tw`text-white leading-normal text-base w-full mb-2`};
  color: white;
`;

export const ButtonCTAContent = styled.div`
    ${tw`w-full mb-2 mt-4 flex`};
    text-align: left;
`;



export const ButtonCTAContainer = styled.div`
  ${tw`text-center`}
`;

export const ButtonCTA = styled.button`
  ${tw`
    bg-theme-text-purple 
    hover:bg-gray-400
    hover:text-theme-background
    text-white 
    rounded-full 
    font-bold py-5 
    px-10 
    no-underline
    shadow
  `};

  margin-top: -15px;
  background-image: url(${btnBGImage});
  background-repeat: no-repeat;
  background-position: right -3px;
  cursor:pointer;
  border: 0px;

  transition: color 0.2s;

  &:hover {
    color: #383838;
    background-color: #00D3AE;
  }

`;

export const Footer = styled.div`
  ${tw`text-left mt-2 flex-row`}
`;

export const FooterText = styled.p`
  ${tw`text-theme-text leading-normal text-base`}
`;

export const FooterTextSmall = styled.p`
  ${tw`text-theme-text leading-normal mt-4 text-xs`}
`;

export const FooterIconsContent = styled.div`
    ${tw`flex`}
`;

export const FooterImageIcon = styled.img`
    ${tw`mt-4`}
    width: 15px;
    margin-right: 10px;
`;