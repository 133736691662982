import styled from 'styled-components';

export const Container = styled.div`
  ${tw``};
`;

export const Separator = styled.hr`
  color: #32303B;
  border-color: #32303B;
  margin-left: 100px;
  margin-right: 100px;
`;

