import React from 'react';

import ContainerMobile from '@components/ContainerMobile';

// import Intro from './Intro';

import {
    Separator,
} from './styles';

const Video01MobilePage = () => (
    <ContainerMobile>
        {/* <Intro /> */}
    </ContainerMobile>
);

export default Video01MobilePage;