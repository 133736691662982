import React from 'react';

import logoImage from '@src/images/marca.svg';

import Content from '@src/components/ContentDesktop';

import ButtonLogin from '@src/components/ButtonLogin';

import {
    Header,
    LogoContent,
    LogoContentImage,
} from './styles';

const DesktopHeaderBlock = () => (
    <Content>
        <Header>
            <LogoContent>
                <LogoContentImage src={logoImage} alt="VPS do Zero ao Avançado" />
            </LogoContent>
        </Header>
    </Content>
);

export default DesktopHeaderBlock;