import styled from 'styled-components';

export const Container = styled.div`
    ${tw``};
`;

export const Header = styled.header`
    ${tw`mt-8 w-full flex`};
`;

export const LogoContent = styled.div`
    ${tw`w-1/6`};
`;

export const LogoContentImage = styled.img`
    ${tw`w-full`};
`;

