import React from 'react';

import Content from '@src/components/ContentDesktop';

import backgroundDetails from '@src/images/listadeespera/background-details.png';

import {
    PageContent,
    Left,
    Title,
    Separator,
    Text,
    TextPurple,
    TextGreen,
    Right,
    RightDetailImage,
    FormLead,
    FormInput,
    ButtonCTAContent,
    ButtonCTA,
    Footer,
    FooterIconsContent,
    FooterText,
    FooterTextSmall,
    FooterImageIcon
} from './styles';

const DesktopContentBlock = () => (
    <Content>
        <PageContent>
            <Left>
                <Title>Lista de espera</Title>
                <Separator />
                <Text><TextPurple>OPS</TextPurple>, INSCRIÇÕES ENCERRADAS!</Text>
                <Text>Você chegou tarde, infelizmente neste exato momento as inscrições para o curso online <TextGreen>VPS do Zero ao Avançado</TextGreen> estão fechadas.</Text>
                {/* <Text>Treinamento passo a passo para você aprender administrar servidores Linux com facilidade utilizando tecnologias de ponta que grandes empresas como <TextGreen>Netflix, Ubber e AirBnb</TextGreen> hospedam seus serviços.</Text> */}
                {/* <Text>Comece sua jornada bootcamp e seja um especialista em infraestrutura web.</Text> */}
                <Text>Deixe seu nome e endereço de e-mail no formulário abaixo que avisarei você em primeira mão sobre abertura de novas vagas:</Text>
                
                <FormLead autocomplete="false" role="form" method="post" action="https://marketing.vpsdozeroaoavancado.com.br/form/submit?formId=1" id="mauticform_l01formulariodesktop" data-mautic-form="l01formulariodesktop" enctype="multipart/form-data">
                    <FormInput 
                        type="text" 
                        required="required" 
                        placeholder="Como gostaria de ser chamado?" 
                        id="mauticform_input_l01formulariodesktop_seu_nome" 
                        name="mauticform[seu_nome]" 
                    />
                    <FormInput 
                        type="email" 
                        required="required" 
                        placeholder="Seu principal endereço de e-mail" 
                        id="mauticform_input_l01formulariodesktop_seu_principal_endereco_de" 
                        name="mauticform[seu_principal_endereco_de]" 
                    />
                    <input type="hidden" name="mauticform[formId]" id="mauticform_l01formulariodesktop_id" value="1" />
                    <input type="hidden" name="mauticform[return]" id="mauticform_l01formulariodesktop_return" value="" />
                    <input type="hidden" name="mauticform[formName]" id="mauticform_l01formulariodesktop_name" value="l01formulariodesktop" />
                    <ButtonCTAContent>
                        <ButtonCTA type="submit">Participe da lista de espera</ButtonCTA>
                    </ButtonCTAContent>
                </FormLead>

                <Footer>
                    <FooterTextSmall>Seus dados estão completamente seguros</FooterTextSmall>
                    <FooterTextSmall>© 2020 · Márcio Dias - Soluções Digitais · CNPJ: 23.269.415/0001-60</FooterTextSmall>
                    {/* <FooterIconsContent>
                        <FooterImageIcon src="https://uploads-ssl.webflow.com/5ce34e4a8578a861f1d83586/5ce34e4a8578a86411d836ab_ic-instagram.svg" />
                        <FooterImageIcon src="https://uploads-ssl.webflow.com/5ce34e4a8578a861f1d83586/5ce34e4a8578a8d2cfd835c1_Fill%2012.svg" />
                    </FooterIconsContent> */}
                </Footer>
            </Left>
            <Right>
                <RightDetailImage src={backgroundDetails} />
            </Right>
        </PageContent>
    </Content>
);

export default DesktopContentBlock;