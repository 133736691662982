import React from 'react';

import ContainerDesktop from '@components/ContainerDesktop';

import Header from './Header';
import Content from './Content';

import {
    Container,
} from './styles';

const Video01DesktopPage = () => (
    <ContainerDesktop>
        <Container>
            <Header />
            <Content />
        </Container>
    </ContainerDesktop>
);

export default Video01DesktopPage;